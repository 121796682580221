/*
* @About gongw created by 2021/2/1
*/
import request from '@/lib/utils/request'
//金融类型----未登录
export function getProList(type,data) {
  return request({
    url: '/buiness/egstype/getListNoToken/'+type,
    method: 'get',
    params: data
  })
}
//金融类型----登录
export function getProListByToken(type,data) {
  return request({
    url: '/buiness/egstype/getList/'+type,
    method: 'get',
    params: data
  })
}
//保函产品----未登录
export function getProduct(data) {
  return request({
    url: '/buiness/finorginfo/listNoToken',
    method: 'get',
    params: data
  })
}
//保函产品----登录
export function getProductByToken(data) {
  return request({
    url: '/buiness/finorginfo/listToken',
    method: 'get',
    params: data
  })
}
//保函产品详情----未登录
export function getProDetail(code, id, data) {
  return request({
    url: '/buiness/prod/selectProdInfo/' + code + '/' + id,
    method: 'get',
    params: data
  })
}
//保函产品详情----登录
export function getProDetailByToken(code, id, data) {
  return request({
    url: '/buiness/prod/selectProdInfoToken/' + code + '/' + id,
    method: 'get',
    params: data
  })
}