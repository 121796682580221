/*
* @About gongw created by 2021/2/1
*/
import axios from 'axios'
import baseUrl from '@/lib/utils/baseUrl'
import store from './../../store'
import { MessageBox } from "element-ui";

const service = axios.create({
  baseURL: baseUrl,
  timeout: 10000
})

// 添加请求拦截器
service.interceptors.request.use(config => {
  // console.log(config)
  let token = window.localStorage.getItem("token")
  if (token) {
    config.headers.Authorization = token;    //将token放到请求头发送给服务器
    return config;
  }
  return config;
}, (error) => {
  console.log(error)
})

// 添加相应拦截器
service.interceptors.response.use(response => {
  if (response.data.code === 401) {
    MessageBox.confirm('未登录，请重新登录!', '提示', {
      confirmButtonText: '确认',
      cancelButtonText: '取消',
      type: 'warning'
    }
    ).then(() => {
      localStorage.removeItem('token')
      localStorage.removeItem('deptId')
      localStorage.removeItem('nickName')
      store.commit('isCheck', null)
      location.href='/'
    }).catch(() => {
      
    });
  }
  if (response.status === 200) {
    return Promise.resolve(response.data)
  } else {
    return Promise.reject(response)
  }
}, (error) => {
  return Promise.reject(error)
})

// 导出service
export default service
