<template>
  <div class="navMenu-body  flex-b flex-a">
    <div class="navMenu-left flex-a">
      <div class="title"><img src="./../assets/logo.svg" alt=""></div>
      <el-menu :default-active="active" style="display: flex;flex-direction: row;white-space: nowrap;" mode="horizontal"
        @select="handleSelect" background-color="#0C5BBB" text-color="#ffffff" active-text-color="#ffd04b">
        <el-menu-item index="1">首页</el-menu-item>
        <el-menu-item index="2">项目标段</el-menu-item>
        <el-menu-item index="3">产品服务</el-menu-item>
        <el-menu-item index="4">用户中心</el-menu-item>
        <el-menu-item index="5">帮助中心</el-menu-item>
        <el-menu-item index="6">保函真伪查询</el-menu-item>
      </el-menu>
    </div>
    <div class="navMenu-right">
      <div class="login flex-a">
        <el-button type="primary" size="small" round @click="login" v-if="!hasLogin">登录</el-button>
        <el-button type="info" round size="small" @click="register" v-if="!hasLogin">注册</el-button>
        <div class="flex-a" v-if="hasLogin">
          <img src="./../assets/head.png" style="width: 25px;margin-right: 10px;" alt="">
          <span style="color:#fff">{{ nickName }}</span>
        </div>
        <el-button type="danger" style="margin-left:10px" round size="small" v-if="hasLogin" @click="loginOut">退出
        </el-button>
      </div>
    </div>
    <!-- 登录-注册弹窗 -->
    <el-dialog :visible.sync="dialogFormVisible" width="300px" :close-on-click-modal="false" @closed="closed">
      <div style="text-align: center;margin-bottom: 10px;"><img src="./../assets/logo-blue.svg" alt=""
          style="width:70%"></div>
      <div class="login-type" v-if="title == '登录'">
        <span :class="type == 'account' ? 'active' : ''" @click="changeType('account')">账号登录</span>
        <span :class="type == 'sms' ? 'active' : ''" @click="changeType('sms')">短信登录</span>
      </div>
      <el-form :model="form" :rules="rules" ref="form" class="addProject-form">
        <div style="font-size:14px;">手机号</div>
        <el-form-item prop="username">
          <el-input v-model="form.username" placeholder="请输入手机号" autocomplete="off"></el-input>
        </el-form-item>
        <div style="font-size:14px;" v-if="type == 'account' || (type == 'sms' && title != '登录')">密码</div>
        <el-form-item v-if="type == 'account' && title == '登录'">
          <el-input v-model="form.password" placeholder="请输入密码" autocomplete="off" show-password></el-input>
        </el-form-item>
        <el-form-item prop="password" v-if="title != '登录'">
          <el-input v-model="form.password" placeholder="请输入密码" autocomplete="off" show-password></el-input>
        </el-form-item>
        <div style="font-size:14px;" v-if="title == '忘记密码' || title == '注册'">确认密码</div>
        <el-form-item prop="confirmPwd" v-if="title == '忘记密码' || title == '注册'">
          <el-input v-model="form.confirmPwd" placeholder="请确认密码" autocomplete="off" show-password></el-input>
        </el-form-item>
        <div style="font-size:14px;" v-if="title == '注册' || title == '忘记密码' || type == 'sms'">验证码</div>
        <el-form-item prop="code" class="get-code" v-if="title == '注册' || title == '忘记密码' || type == 'sms'">
          <el-input v-model="form.code" @keyup.enter.native="submit('form')" placeholder="请输入验证码"
            style="width: 60%;margin-right: 3%;"></el-input>
          <el-button v-show="countshow" size="mini" style="font-size: 13px;" @click="getcode">获取验证码</el-button>
          <el-button v-show="!countshow" size="mini" style="font-size: 13px;" disabled>{{ count
          }}
            s</el-button>
        </el-form-item>
        <div v-if="title == '登录'" class="forget-psd"><span @click="forgetPsd">忘记密码？</span></div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submit('form')"
          style="width:100%;border-radius: 20px;height: 30px;line-height: 30px;padding: 0;font-size: 14px;">{{ btnTxt }}
        </el-button>
      </div>
      <div class="agree">
        <el-checkbox v-model="checked"></el-checkbox><span style="margin-left:5px">阅读并接受<span class="agree-hover"
            style="color: #4E6EF2;" @click="toAgree">《用户注册及使用网站隐私协议》</span></span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { login, register, forgetPsd, getPublicKey, logout } from '@/lib/api/login'
import { getNoCode } from '../lib/api/project'
import { encrypt } from './../lib/utils/jsencrypt'
export default {
  name: 'navMenu',
  components: {},
  props: {
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.form.confirmPwd !== '') {
          this.$refs.form.validateField('confirmPwd');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.form.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      type: 'account',
      checked: false,
      nickName: '',
      btnTxt: '登 录',
      active: '1',
      title: '',
      dialogFormVisible: false,
      codeMsg: '获取验证码',
      disabled: false,
      hasLogin: false,
      form: {
        username: '',
        password: '',
        code: '',
        confirmPwd: ''
      },
      rules: {
        password: [
          { required: true, message: '请填写密码', trigger: 'blur' },
          { pattern: /(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z]){8,30}/, message: '必须包含大小字母、数字，至少8位' }
        ],
        confirmPwd: [
          { required: true, message: '请确认密码', trigger: 'blur' },
          { pattern: /(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z]){8,30}/, message: '必须包含大小字母、数字，至少8位' },
          { validator: validatePass2, trigger: 'blur', required: true }
        ],
        username: [
          { required: true, message: '请填写注册手机号', trigger: 'blur' },
          { pattern: /^1(3|4|5|6|7|8|9)\d{9}$/, message: '手机号格式不正确' }
        ],
        code: [
          { required: true, message: '请填写验证码', trigger: 'blur' }
        ]
      },
      formLabelWidth: '60px',
      t: 60,
      timer: null,
      countshow: true,
      count: ''
    }
  },
  watch: {},
  created() {
    var path = this.$route.path
    switch (path) {
      case '/':
        this.active = '1';
        break;
      case '/project':
        this.active = '2';
        break;
      case '/product':
        this.active = '3';
        break;
      case '/attestation':
        this.active = '4';
        break;
      case '/help':
        this.active = '5';
        break;
      case '/search':
        this.active = '6';
        break;
    }
    if (localStorage.getItem('token')) {
      this.hasLogin = true
    } else {
      this.hasLogin = false
    }
    if (localStorage.getItem('nickName')) {
      this.nickName = localStorage.getItem('nickName')
    }
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    // 获取验证码
    async getcode() {
      if (this.form.username == '') {
        this.$message({
          message: '请先填写手机号',
          type: 'warning'
        });
      } else {
        const TIME_COUNT = 60;
        if (!this.timer) {
          this.count = TIME_COUNT;
          this.countshow = false;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--;
            } else {
              this.countshow = true;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000)
        }
        this.codesuccess() // 调用接口获取验证码
      }
    },
    codesuccess() {
      this.form.code = ''
      getNoCode({ phone: this.form.username }).then(res => {
        if (res.code == 200) {
          this.$message({
            message: '已发送',
            type: 'success'
          });
        }
      })
    },
    closed() {
      this.countshow = true;
      clearInterval(this.timer);
      this.timer = null;
      this.$refs['form'].resetFields()
    },
    toAgree() {
      let routeData = this.$router.resolve({
        name: "agree"
      });
      window.open(routeData.href, '_blank');
    },
    //切换登录方式
    changeType(str) {
      this.type = str
    },
    //导航菜单切换
    handleSelect(key, keyPath) {
      this.active = key
      switch (key) {
        case '1':
          this.$router.push('/');
          break;
        case '2':
          this.$router.push('/project' + '?t=' + new Date().getTime())
          break;
        case '3':
          this.$router.push('/product')
          break;
        case '4':
          this.$router.push('/attestation')
          break;
        case '5':
          this.$router.push('/help')
          break;
        case '6':
          this.$router.push('/search')
          break;
      }
    },
    //退出登录
    loginOut() {
      logout(localStorage.getItem('token')).then(res => {
        if (res.code == 200) { }
        localStorage.removeItem('token')
        localStorage.removeItem('deptId')
        localStorage.removeItem('nickName')
        this.$router.go(0)
      })
    },
    //登录
    login() {
      this.title = '登录'
      this.btnTxt = '登  录'
      this.t = 60
      clearInterval(this.timer)
      this.codeMsg = '获取验证码'
      this.disabled = false
      this.dialogFormVisible = true
      this.form = {
        username: '',
        password: '',
        code: ''
      }
    },
    //忘记密码
    forgetPsd() {
      this.title = '忘记密码'
      this.btnTxt = '确  认'
      this.t = 60
      this.codeMsg = '获取验证码'
      this.disabled = false
      clearInterval(this.timer)
      this.dialogFormVisible = true
      this.form = {
        username: '',
        password: '',
        code: ''
      }
    },
    //注册
    register() {
      this.title = '注册'
      this.btnTxt = '注  册'
      this.t = 60
      this.codeMsg = '获取验证码'
      this.disabled = false
      clearInterval(this.timer)
      this.dialogFormVisible = true
      this.form = {
        username: '',
        password: '',
        code: '',
        confirmPwd: ''
      }
    },
    //提交
    submit(formName) {
      var that = this
      if (that.title == '登录') {
        getPublicKey().then(res => {
          if (that.checked == true) {
            that.form.password = encrypt(this.form.password, res.publicKey)
            // that.form.confirmPwd = encrypt(this.form.confirmPwd, res.publicKey)
            if (that.type == 'sms') {
              delete that.form.password
            }
            login(that.form).then(res => {
              if (res.code == 200) {
                that.$message({
                  message: '登录成功！',
                  type: 'success'
                });
                that.nickName = res.data.userName
                that.$store.commit('login', res.data.token)
                that.$store.commit('isCheck', res.data.deptId)
                localStorage.setItem('nickName', res.data.user.userName)
                localStorage.setItem('userId', res.data.user.userId)
                that.dialogFormVisible = false
                if (res.data.deptId == 'null') {
                  this.$alert('请前往账号认证!', '提示', {
                    confirmButtonText: '确定',
                    callback: action => {
                      this.$router.push('/attestation')
                    }
                  });
                } else {
                  this.$router.go(0)
                }
              } else {
                this.$message({
                  message: res.msg,
                  type: 'error'
                });
              }
            })
          } else {
            this.$message({
              message: '请阅读并同意《用户注册及使用网站隐私协议》',
              type: 'warning'
            });
          }
        })
      } else if (that.title == '注册') {
        if (that.checked == true) {
          that.$refs[formName].validate((valid) => {
            if (valid) {
              // that.form.password = encrypt(this.form.password, res.publicKey)
              // that.form.confirmPwd = encrypt(this.form.confirmPwd, res.publicKey)
              register(that.form).then(res => {
                if (res.code == 200) {
                  that.dialogFormVisible = false
                  this.$confirm('注册成功,请前往登录！', '提示', {
                    confirmButtonText: '去登录',
                    cancelButtonText: '取消',
                    type: 'success'
                  }).then(() => {
                    that.login()
                  }).catch(() => {
                  });
                } else {
                  that.$message.error(res.msg);
                }
              })
            } else {
              return false;
            }
          });
        } else {
          this.$message({
            message: '请阅读并同意《用户注册及使用网站隐私协议》',
            type: 'warning'
          });
        }
      } else if (that.title == '忘记密码') {
        getPublicKey().then(res => {
          if (that.checked == true) {
            that.form.password = encrypt(this.form.password, res.publicKey)
            forgetPsd(that.form).then(res => {
              if (res.code == 200) {
                that.dialogFormVisible = false
                that.$alert('密码修改成功,请前往登录！', '提示', {
                  confirmButtonText: '确定',
                  callback: action => {
                  }
                });
              } else {
                that.$message.error(res.msg);
              }
            })

          } else {
            that.$message({
              message: '请阅读并同意《用户注册及使用网站隐私协议》',
              type: 'warning'
            });
          }
        })
      }
    },
  },
  computed: {

  }
}
</script>

<style lang="less">
// .el-menu--horizontal>.el-menu-item.is-active,
// .el-menu--horizontal>.el-menu-item,
// .el-menu--horizontal>.el-submenu.is-active .el-submenu__title {
//   border: none !important;
// }
.el-submenu__title i {
  color: #fff !important;
}

.navMenu-body {
  width: 100%;
  background: #0C5BBB;
  padding: 0 20%;
  box-sizing: border-box;
  white-space: nowrap;

  // .el-menu{
  //   background: transparent;
  // }
  .navMenu-left {
    .title {
      color: #fff;
      font-size: 18px;
      font-weight: bold;
      margin-right: 100px;

      img {
        width: 200px;
      }
    }
  }

  .navMenu-right {
    color: #727E89;

    .login {
      font-size: 14px;
    }

    .login:hover {
      cursor: pointer;
    }

    .head-img {
      display: inline-block;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background: #75808B;
      margin-right: 10px;
    }
  }

  .loginOut .el-submenu__icon-arrow {
    display: none;
  }
}

// .addProject-form {
//   .el-input__inner {
//     border-radius: 20px;
//   }
// }

.el-dialog__body {
  padding: 30px 20px 0 !important;
}

.agree {
  font-size: 12px;
}

.agree-hover:hover {
  cursor: pointer;
}

.forget-psd {
  font-size: 14px;
  text-align: right;
  position: relative;
  top: 0px;
  color: #4E6EF2;

  span:hover {
    cursor: pointer;
  }
}

.login-type {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 40px;
  border-bottom: 1px solid #ddd;

  span {
    display: inline-block;
    width: 90px;
    text-align: center;
    color: #999;
    font-weight: bold;
    padding-bottom: 5px;
    border-bottom: 2px solid transparent;
  }

  span:hover {
    cursor: pointer;
  }

  span.active {
    color: #409EFF;
    font-weight: bold;
    border-color: #409EFF;
  }
}
</style>
