<template>
  <div class="index-containter">
    <nav-menu></nav-menu>
    <div class="index-body">
      <div class="index-head">
        <div class="big-title">一站式保函办理平台</div>
        <div class="sub-title">专业可靠/一键申请/最快3分钟出函/真伪可查</div>
        <div class="index-notice flex-a">
          <div class="notice-list flex-b">
            <div class="notice-item" v-for="item in noticeList" :key="item.id" @click="toDetail(item.id)">
              <div class="time">{{ item.createTime ? item.createTime.split(' ')[0] : '2022-08-11 12:00' }}</div>
              <div class="title">{{ item.name }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="product-service flex">
        <div class="left">
          <div class="title">PRODUCTS</div>
          <div class="title">SERVICES</div>
          <div class="line"></div>
          <div class="name">产品服务</div>
          <div class="tab-list">
            <div :class="code == item.code ? 'tab-item active flex-a' : 'tab-item flex-a'" v-for="item in tabList"
              :key="item.id" @click="changeTab(item.code)">
              <span></span>
              <div>{{ item.name }}</div>
            </div>
          </div>
          <div class="more flex-a" @click="toMore('/product')">
            <span>了解更多</span>
            <img src="./../assets/right.png" alt="">
          </div>
        </div>
        <div class="right">
          <div class="pro-list" v-if="productList.length > 0">
            <div class="pro-item" v-for="item in productList" :key="item.id" @click="checkDetail(item.id)">
              <div class="head flex-a flex-b">
                <div>融易贷（抵押）</div>
                <div>
                  <!-- <img :src="item.logo" alt=""> -->
                  {{item.name}}
                </div>
              </div>
              <div class="content">
                {{ item.introduce }}
              </div>
              <div class="bottom">
                <div>参考利率：3.25%-4.25%</div>
                <div>贷款期限：6个月及以下</div>
                <div>担保方式：担保</div>
                <div>贷款额度：1.00-300.00万元</div>
              </div>
            </div>
          </div>
          <div v-else>
            该产品未对外开放，敬请期待！
          </div>
        </div>
      </div>
      <div class="news flex">
        <div class="left">
          <div class="title">NEWS</div>
          <div class="line"></div>
          <div class="name">行业新闻</div>
          <div class="more flex-a">
            <span>了解更多</span>
            <img src="./../assets/right.png" alt="">
          </div>
        </div>
        <div class="right">
          <div class="news-list">
            <div class="news-item" v-for="item in newsList" :key="item.id" @click="toDetail(item.id)">
              <div class="head flex-a flex-b">
                <img :src="item.url" alt="">
              </div>
              <div class="content flex-a flex-b">
                <div>{{ item.createTime }}</div>
                <!-- <div class="tags">中央</div> -->
              </div>
              <div class="bottom">
                {{ item.remark }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <foot></foot>
    </div>
  </div>
</template>

<script>
import { getInfo, getInfoByToken, getProduct, getProductByToken, getDataInfo, getDataInfoByToken } from './../lib/api/index'
import { getProList, getProListByToken } from './../lib/api/product'
export default {
  name: 'index',
  components: {},
  data() {
    return {
      tabList: [],
      code: '',
      btn: 1,
      newsList: [],
      noticeList: [],
      productList: [],
      dataList: []
    }
  },
  created() {
    if (window.localStorage.getItem("token")) {
      this.getInfoByToken()//查看首页资讯，通知---登录
      this.getProductByToken({ pageNum: 1, pageSize: 10 })//产品-登录
      this.getDataInfoByToken()//数据汇总--登录
      this.getProListByToken()// 金融产品分类-登录
    } else {
      this.getInfo()//查看首页资讯，通知----未登录
      this.getProduct({ pageNum: 1, pageSize: 10 })//产品-未登录
      this.getDataInfo()//数据汇总--未登录
      this.getProList()// 金融产品分类-未登录
    }
  },
  methods: {
    //查看首页资讯，通知----未登录
    getInfo() {
      getInfo().then(res => {
        if (res.code == 200) {
          res.data[0].forEach((item, index) => {
            if (index < 3) {
              this.noticeList.push(item)
            }
          })
          this.newsList = res.data[1]
        }
      })
    },
    //查看首页资讯，通知----登录
    getInfoByToken() {
      getInfoByToken().then(res => {
        if (res.code == 200) {
          res.data[0].forEach((item, index) => {
            if (index < 3) {
              this.noticeList.push(item)
            }
          })
          this.newsList = res.data[1]
        }
      })
    },
    //产品-登录
    getProductByToken(params) {
      getProductByToken(params).then(res => {
        if (res.code == 200) {
          var arr = []
          res.rows.forEach((item, index) => {
            if (index < 4) {
              arr.push(item)
            }
          })
          this.productList = arr
        }
      })
    },
    //产品-未登录
    getProduct(params) {
      getProduct(params).then(res => {
        if (res.code == 200) {
          var arr = []
          res.rows.forEach((item, index) => {
            if (index < 4) {
              arr.push(item)
            }
          })
          this.productList = arr
        }
      })
    },
    //数据汇总--- 未登录
    getDataInfo() {
      getDataInfo().then(res => {
        if (res.code == 200) {
          this.dataList = res.data
        }
      })
    },
    //数据汇总--登录
    getDataInfoByToken() {
      getDataInfoByToken().then(res => {
        if (res.code == 200) {
          this.dataList = res.data
        }
      })
    },
    //产品类别切换
    changeTab(code) {
      this.code = code
      if (window.localStorage.getItem("token")) {
        this.getProductByToken({ pageNum: 1, pageSize: 10, code: this.code })//产品-登录
      } else {
        this.getProduct({ pageNum: 1, pageSize: 10, code: this.code })//产品-未登录
      }
    },
    // 金融产品分类-未登录
    getProList() {
      getProList(0).then(res => {
        if (res.code == 200) {
          this.tabList = res.data
          this.code = res.data[0].code
          this.getProduct({ pageNum: 1, pageSize: 10, code: this.code })//产品-未登录
        }
      })
    },
    // 金融产品分类-登录
    getProListByToken() {
      getProListByToken(0).then(res => {
        if (res.code == 200) {
          this.tabList = res.data
          this.code = res.data[0].code
          this.getProductByToken({ pageNum: 1, pageSize: 10, code: this.code })//产品-登录
        }
      })
    },
    //跳转新闻详情
    toDetail(id) {
      this.$router.push({
        path: '/detail',
        query: { id: id }
      })
    },
    // 了解更多
    toMore(path) {
      this.$router.push({
        path
      })
    },
    //更多
    toNotice() {
      this.$router.push({
        path: '/notice'
      })
    },
    // 查看详情
    checkDetail(id) {
      this.$router.push({ path: '/product_detail', query: { id: id, code: this.code } })
    },
  }
}
</script>
<style lang="less">
.index-containter {
  background: #F6FAFF;
  min-height: 100vh;

  .index-body {
    .index-head {
      width: 100%;
      background: url(./../assets/index_bg_2.png)no-repeat 0 0;
      background-size: 100%;
      min-height: 55vh;
      position: relative;
      color: #fff;
      font-size: 20px;

      div.big-title {
        position: absolute;
        bottom: 250px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 46px;
      }

      div.sub-title {
        position: absolute;
        bottom: 180px;
        left: 50%;
        transform: translateX(-50%);
      }

      .index-notice {
        background: rgba(56, 123, 240, 0.2);
        color: #fff;
        padding: 10px 0;
        box-sizing: border-box;
        position: absolute;
        bottom: 0;
        width: 100%;

        .notice-title {
          margin-right: 40px;

          img {
            width: 40px;
            margin-right: 5px;
          }

          .title {
            font-size: 20px;
            font-weight: bold;
          }

          .txt {
            font-size: 10px;
          }
        }

        .notice-list {
          width: 60%;
          margin: 0 auto;

          .notice-item {
            display: inline-block;
            width: 27%;
            white-space: nowrap;

            img {
              width: 20px;
              vertical-align: middle;
            }

            .title {
              display: inline-block;
              width: 100%;
              font-size: 14px;
              vertical-align: middle;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              word-break: break-all;
            }

            .time {
              font-size: 12px;
              vertical-align: middle;
            }
          }

          .notice-item:nth-child(1),
          .notice-item:nth-child(2) {
            margin-bottom: 5px;
          }

          .notice-item:hover {
            cursor: pointer;
          }
        }

        .notice-more {
          font-size: 12px;
          position: absolute;
          right: 20px;
        }

        .notice-more:hover {
          cursor: pointer;
        }
      }
    }

    .product-service {
      background: #E1EBFC;
      width: 100%;
      box-sizing: border-box;
      padding: 40px 0 80px 20%;

      .left {
        width: 15%;

        .title {
          color: #000;
          font-weight: bold;
          font-size: 20px;
        }

        .line {
          background: #C2CEFF;
          width: 110px;
          height: 2px;
          margin: 15px 0;
        }

        .name {
          color: #333;
          font-size: 18px;
          font-weight: bold;
        }

        .tab-list {
          margin-top: 40px;

          .tab-item {
            margin-bottom: 30px;

            span {
              display: inline-block;
              background: #BEC6D3;
              width: 25px;
              height: 2px;
              margin-right: 15px;
            }
          }

          .tab-item.active {
            span {
              background: #387BF0;
            }

            div {
              color: #387BF0;
            }
          }

          .tab-item:hover {
            cursor: pointer;
          }
        }

        .more {
          background: #387BF0;
          border-radius: 20px;
          width: 120px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          color: #fff;
          font-size: 12px;
          box-sizing: border-box;
          white-space: nowrap;
          padding: 0 20px;

          img {
            width: 30px;
            margin-left: 10px;
          }
        }

        .more:hover {
          cursor: pointer;
        }
      }

      .right {
        width: 85%;
        padding-right: 50px;
        box-sizing: border-box;

        .pro-list {
          .pro-item {
            display: inline-block;
            background: #ffff;
            border-radius: 10px;
            padding: 10px 15px 20px;
            box-sizing: border-box;
            height: 196px;
            width: 32.5%;
            margin-bottom: 15px;
            vertical-align: top;

            .head {
              color: #333;
              font-weight: bold;
              height: 49px;

              img {
                width: 100px;
              }
            }

            .content {
              color: #B8B8B8;
              font-size: 12px;
              text-align: justify;
              padding-bottom: 30px;
              display: -webkit-box;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
            }

            .bottom {
              color: #B8B8B8;
              font-size: 12px;

              div {
                display: inline-block;
                width: 50%;
                margin-bottom: 10px;
                white-space: nowrap;
              }

              div:nth-child(even) {
                text-align: right;
              }
            }
          }

          .pro-item:nth-child(3n+2) {
            margin: 0 1.25%;
          }

          .pro-item:hover {
            cursor: pointer;
          }
        }
      }
    }

    .news {
      background: #F6FAFF;
      width: 100%;
      box-sizing: border-box;
      padding: 40px 0 20px 20%;

      .left {
        width: 15%;

        .title {
          color: #000;
          font-weight: bold;
          font-size: 20px;
        }

        .line {
          background: #C2CEFF;
          width: 110px;
          height: 2px;
          margin: 15px 0;
        }

        .name {
          color: #333;
          font-size: 18px;
          font-weight: bold;
        }

        .more {
          background: #387BF0;
          border-radius: 20px;
          width: 120px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          color: #fff;
          font-size: 12px;
          box-sizing: border-box;
          white-space: nowrap;
          padding: 0 20px;
          margin-top: 450px;

          img {
            width: 30px;
            margin-left: 10px;
          }
        }

        .more:hover {
          cursor: pointer;
        }
      }

      .right {
        width: 85%;
        padding-right: 300px;
        box-sizing: border-box;

        .news-list {
          .news-item {
            display: inline-block;
            border-radius: 10px;
            box-sizing: border-box;
            width: 32.5%;
            margin-bottom: 30px;

            .head {
              img {
                width: 100%;
              }
            }

            .content {
              color: #707070;
              font-size: 14px;
              padding: 0 10px;
              box-sizing: border-box;
              margin: 15px 0 20px;

              .tags {
                border: 1px solid #FCA62E;
                border-radius: 5px;
                background: #F7E5CC;
                color: #FD980B;
                width: 40px;
                text-align: center;
                height: 20px;
                line-height: 20px;
              }
            }

            .bottom {
              color: #858687;
              font-size: 14px;
              padding: 0 10px;
              overflow: hidden;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              white-space: wrap;
            }
          }

          .news-item:nth-child(3n+2) {
            margin: 0 1.25%;
          }
        }
      }
    }

    .index-use {
      margin-top: 30px;

      .index-txt {
        color: #5A5A5A;
      }

      .index-line {
        width: 45%;
        height: 1px;
        background: #E8E8E8;
      }
    }

    .index-btns {
      padding: 30px 20%;

      .btn {
        border: 1px solid #D9D9D9;
        background: #fff;
        width: 280px;
        text-align: center;
        height: 60px;
        line-height: 60px;
        border-radius: 5px;
        color: #5A5A5A;
        font-size: 18px;
      }

      .btn:hover {
        cursor: pointer;
      }

      .active {
        background: #1890FF;
        border-block: #1890FF;
        color: #fff;
      }
    }

    .index-product {
      margin: 20px 0 0;
      width: 100%;

      .product-item {
        display: inline-block;
        border: 1px solid #DCDCDC;
        text-align: center;
        width: 24%;
        height: 280px;
        padding: 0 16px;
        transition: all .15s linear;
        vertical-align: top;
        box-sizing: border-box;
        margin-bottom: 20px;
        position: relative;

        div:first-child {
          width: 100%;
          height: 80px;
          text-align: center;
          position: relative;

          img {
            width: 70%;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }

        .text {
          font-size: 15px;
          color: #666;
          line-height: 24px;
          overflow: hidden;
          margin-top: 5px;
          text-align: left;
        }

        span {
          box-sizing: border-box;
          display: block;
          margin: 0 auto;
          width: 80px;
          text-align: center;
          height: 30px;
          line-height: 28px;
          border-radius: 25px;
          margin-top: 25px;
          font-size: 14px;
          color: #2979dc;
          border: 1px solid #2979dc;
          transition: all .15s linear;
          position: absolute;
          bottom: 20px;
          left: 50%;
          transform: translateX(-50%);
        }

        span:hover {
          cursor: pointer;
          color: #fff;
          background: #2979dc;
        }
      }

      .product-item:hover {
        border-color: #2979dc;
        box-shadow: 0 0 10px #E7ECF2;
      }

      .product-item:nth-child(4n+2) {
        margin: 0 1.33%;
      }

      .product-item:nth-child(4n+3) {
        margin-right: 1.33%;
      }
    }

    .index-data {
      width: 100%;
      background: #fff;
      box-sizing: border-box;

      .data-head {
        text-align: center;

        img {
          width: 40px;
          margin-top: 20px;
        }
      }

      .data-analysis {
        text-align: center;

        p {
          font-size: 28px;
          margin: 10px 0 10px;
          color: #181818;
          font-weight: 540;
          letter-spacing: 2px;
        }

      }

      .data-des {
        text-align: center;
        font-size: 14px;
      }

      .data-list {
        padding: 40px 15% 0;

        .data-item {
          text-align: center;

          img {
            width: 80%;
          }

          .num {
            font-size: 30px;
            margin: 10px 0 0;
          }
        }
      }
    }

    .index-guarantee {
      img {
        width: 100%;
        float: left;
      }
    }
  }

  .news-list {
    position: relative;
    top: 20px;

    .news-title {
      text-align: center;
      padding: 10px 0;

      .news-big-title {
        color: #333;
        font-size: 16px;
        font-weight: bold;
      }

      .news-sub-title {
        justify-content: center;
        font-size: 12px;
        padding: 5px 0;

        .line {
          display: inline-block;
          width: 10px;
          height: 1px;
          background: #7f7f7f;
        }

        span:nth-child(2) {
          padding: 0 5px;
        }
      }
    }

    .news-item-box {
      display: inline-block;
      width: 50%;
      box-sizing: border-box;
    }

    .news-item {
      margin-bottom: 10px;

      .news-left {
        width: 80px;
        height: 80px;
        border-radius: 2px;
        box-sizing: border-box;
        background: #BFBFBF;
        color: #FFFFFF;
        position: relative;
        margin-right: 15px;

        .news-left-box {
          text-align: center;
          white-space: nowrap;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);

          .news-item-day {
            font-size: 20px;
            margin-bottom: 2px;
          }

          .news-item-year {
            font-size: 14px;
          }
        }
      }

      .news-right {
        padding-right: 10px;
        box-sizing: border-box;
        width: 85%;

        .news-item-title {
          font-size: 16px;
          color: #333;
          margin-bottom: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
        }

        .news-item-des {
          color: #999;
          font-size: 12px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
      }
    }

    .news-item:hover {
      cursor: pointer;
    }
  }
}
</style>
