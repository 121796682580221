
import Vue from "vue"
import Vuex from "vuex"
import { login } from "../lib/api/login";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        pathName: "",
        token: '',
        deptId: null
    },
    mutations: {
        // 保存当前菜单栏的路径
        savePath(state, pathName) {
            state.pathName = pathName;
        },
        //登录
        login(state, token) {
            state.token = token
            localStorage.setItem('token', token)
        },
        //是否认证
        isCheck(state, deptId) {
            state.deptId = deptId
            localStorage.setItem('deptId', deptId)
        }
    }
})