/*
* @About gongw created by 2021/2/1
*/
import request from '@/lib/utils/request'

// 获取项目标段列表--登录
export function getList(data) {
  return request({
    url: '/buiness/info/listAndStatus',
    method: 'get',
    params: data
  })
}
// 获取项目标段列表--未登录
export function getListNoToken(data) {
  return request({
    url: '/buiness/info/tListNoToken',
    method: 'get',
    params: data
  })
}
//添加经办人信息
export function addInfo(data) {
  return request({
    url: '/buiness/people/add',
    method: 'post',
    data: data
  })
}
//立即支付
export function pay(data) {
  return request({
    url: '/egs/pay/payForOrder',
    method: 'post',
    params: data
  })
}
//立即申请状态--登录
export function applyState(data) {
  return request({
    url: '/buiness/people/ckpeople',
    method: 'post',
    data: data
  })
}
//立即申请状态-未登录
export function applyStateNoToken(data) {
  return request({
    url: '/buiness/people/ckpeopleNoToken',
    method: 'post',
    params: data
  })
}
//获取短信验证码
export function getCode(data) {
  return request({
    url: '/egs/pay/getVerCode',
    method: 'post',
    params: data
  })
}
//获取短信验证码-注册
export function getNoCode(data) {
  return request({
    url: '/egs/pay/getVerCodeNoToken',
    method: 'post',
    params: data
  })
}
// 获取产品类别--保函产品级联菜单
export function getCascaderList(data) {
  return request({
    url: '/buiness/prod/getProdTree',
    method: 'get',
    params: data
  })
}
// 获取市级--未登录
export function getCityList(data) {
  return request({
    url: '/buiness/place/getPlaceTitleListNoToken',
    method: 'get',
    params: data
  })
}
// 获取市级--登录
export function getCityListByToken(data) {
  return request({
    url: '/buiness/place/getPlaceTitleList',
    method: 'get',
    params: data
  })
}
// 获取市级--未登录
export function getCountyList(id, data) {
  return request({
    url: '/buiness/place/getPlaceByIdNoToken/' + id,
    method: 'get',
    params: data
  })
}
// 获取市级--登录
export function getCountyListByToken(id, data) {
  return request({
    url: '/buiness/place/getPlaceById/' + id,
    method: 'get',
    params: data
  })
}
// 获取担保公司列表
export function getProductList(data) {
  return request({
    url: '/buiness/prod/getProList',
    method: 'get',
    params: data
  })
}
// 获取担保公司列表2
export function getProListByPrjNo(data) {
  return request({
    url: '/buiness/prod/getProListByPrjNo',
    method: 'get',
    params: data
  })
}
//  选择金融机构后提交 
export function subProduct(data) {
  return request({
    url: '/buiness/people/addProd',
    method: 'get',
    params: data
  })
}
//  获取二级下拉保函产品--未登录
export function getMapTreeNoToken(data) {
  return request({
    url: '/buiness/egstype/getMapTreeNoToken',
    method: 'get',
    params: data
  })
}
//  获取二级下拉保函产品--登录
export function getMapTree(data) {
  return request({
    url: '/buiness/egstype/getMapTree',
    method: 'get',
    params: data
  })
}
//  添加项目标段并申请额度
export function postProject(data) {
  return request({
    url: '/buiness/notice',
    method: 'post',
    data: data
  })
}
//  删除中标通知附件
export function delFile(data) {
  return request({
    url: '/buiness/upload/comRemove',
    method: 'post',
    data: data
  })
}
//  获取业务员列表
export function getBusMap(data) {
  return request({
    url: '/buiness/buiness/getBusMap',
    method: 'get',
    params: data
  })
}