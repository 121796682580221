import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: index
  },
  {
    path: '/project',
    name: 'project',
    component: () => import('../views/project.vue')
  },
  {
    path: '/attestation',
    name: 'attestation',
    component: () => import('../views/attestation.vue')
  },
  {
    path: '/order',
    name: 'order',
    component: () => import('../views/order.vue')
  },
  {
    path: '/orderDetail',
    name: 'orderDetail',
    component: () => import('../views/orderDetail.vue')
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import('../views/detail.vue')
  },
  {
    path: '/notice',
    name: 'notice',
    component: () => import('../views/notice.vue')
  },
  {
    path: '/product',
    name: 'product',
    component: () => import('../views/product.vue')
  },
  {
    path: '/product_detail',
    name: 'proDetail',
    component: () => import('../views/product_detail.vue')
  },
  {
    path: '/loan',
    name: 'loan',
    component: () => import('../views/loan.vue')
  },
  {
    path: '/apply',
    name: 'apply',
    component: () => import('../views/apply.vue')
  },
  {
    path: '/addProject',
    name: 'addProject',
    component: () => import('../views/addProject.vue')
  },
  {
    path: '/help',
    name: 'help',
    component: () => import('../views/help.vue')
  },
  {
    path: '/message',
    name: 'message',
    component: () => import('../views/message.vue')
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('../views/search.vue')
  },
  {
    path: '/agree',
    name: 'agree',
    component: () => import('../views/agree.vue')
  },
  {
    path: '/insurance',
    name: 'insurance',
    component: () => import('../views/insurance.vue')
  },
  {
    path: '/advance',
    name: 'advance',
    component: () => import('../views/advance.vue')
  },
  {
    path: '/approval',
    name: 'approval',
    component: () => import('../views/approval.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
