/*
* @About gongw created by 2021/2/23
*/
let baseUrl = ''
if (process.env.NODE_ENV === 'production') {
  // baseUrl = 'http://192.168.0.13:8998'//本地
  // baseUrl = 'http://81.68.122.123:8998'//测试
  baseUrl = 'https://www.ygctszfw.com/api'//线上
  // baseUrl = 'http://81.68.122.123:8888'
} else {
  // baseUrl = 'http://192.168.0.13:8998'//本地
  // baseUrl = 'http://81.68.122.123:8998'//测试
  baseUrl = 'https://www.ygctszfw.com/api'//线上
  // baseUrl = 'http://81.68.122.123:8888'
}
export default baseUrl

