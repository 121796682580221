/*
* @About gongw created by 2021/2/1
*/
import request from '@/lib/utils/request'

// 登录
export function login (params) {
  return request({
    url: '/login',
    method: 'post',
    data: params
  })
}
// 退出
export function logout (params) {
  return request({
    url: '/logout',
    method: 'post',
    data: params
  })
}
// 注册
export function register (params) {
  return request({
    url: '/register',
    method: 'post',
    data: params
  })
}
// 忘记密码
export function forgetPsd (params) {
  return request({
    url: '/resetLogin',
    method: 'post',
    data: params
  })
}
export function getPublicKey() {
  return request({
    url: '/publicKey',
    method: 'get',
  })
}
