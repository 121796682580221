<template>
  <div id="app">
    <transition :name="direction">
      <router-view :key="$route.fullPath" />
    </transition>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      direction: 'slide-right'
    }
  },
  created() {
  },
  beforeDestroy() {
  },
  mounted() {
  },
  methods: {},
  watch: {
    $route(to, from) {
      const toDepth = to.path.split('/').length
      const fromDepth = from.path.split('/').length
      if (to.path === '/') {
        this.direction = 'slide-right'
      } else if (from.path === '/') {
        this.direction = 'slide-left'
      } else {
        this.direction = toDepth < fromDepth ? 'slide-right' : 'slide-left'
      }
    }
  }
}
</script>
<style lang="less">
html body {
  margin: 0;
  padding: 0;
}

#app {
  font-family: 'Noto Sans SC';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  overflow: auto;
  min-width: 1200px;
}

.el-menu.el-menu--horizontal {
  border-bottom: none !important;
}

.navMenu-right .el-submenu.is-active .el-submenu__title {
  border-bottom-color: transparent !important;
}

.el-cascader-node__label {
  font-weight: bold !important;
}

.appView {
  position: absolute;
  width: 100%;
  transition: transform 0.3s ease-out;
}

.slide-left-enter {
  transform: translate(100%, 0);
}

.slide-left-leave-active {
  transform: translate(-50%, 0);
}

.slide-right-enter {
  transform: translate(-50%, 0);
}

.slide-right-leave-active {
  transform: translate(100%, 0);
}

.flex {
  display: flex;
  flex-direction: row;
}

.flex-a {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-b {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.flex-d {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.containter {
  background: #fff;
  padding: 20px 30px 50px;
  box-sizing: border-box;
  width: 60%;
  min-width: 600px;
  min-height: 90vh;
  margin: 0 auto;
}

@media screen and (max-width: 1800px) {

  .containter,
  .product-apply,
  .product-list {
    width: 65% !important;
  }
}

@media screen and (max-width: 1750px) {

  .containter,
  .product-apply,
  .product-list {
    width: 70% !important;
  }
}

@media screen and (max-width: 1600px) {

  .containter,
  .product-apply,
  .product-list {
    width: 75% !important;
  }
}

@media screen and (max-width: 1500px) {

  .containter,
  .product-apply,
  .product-list {
    width: 80% !important;
  }
}

@media screen and (max-width: 1400px) {

  .containter,
  .product-apply,
  .product-list {
    width: 85% !important;
  }
}

@media screen and (max-width: 1300px) {

  .containter,
  .product-apply,
  .product-list {
    width: 90% !important;
  }
}

@media screen and (max-width: 1250px) {
  .index-bond {
    .el-carousel__container {
      height: 230px !important;
    }
  }
}
@media screen and (max-width: 1800px) {
  .product-service,.news{
    padding: 40px 0 80px 15% !important;
  }
}
@media screen and (max-width: 1600px) {
  .product-service,.news{
    padding: 40px 0 80px 10% !important;
  }
}
@media screen and (max-width: 1400px) {
  .product-service,.news{
    padding: 40px 0 80px 5% !important;
  }
}
.project-containter,
.attestation-form,
.order-search,
.addProject-form {
  .el-input__inner {
    height: 30px;
  }

  .el-pagination {
    text-align: center;
  }

  .el-form-item {
    margin-bottom: 15px;
  }

  .el-form-item__label {
    padding: 0;
  }

  .el-cascader {
    width: 100%;
  }

  .get-code {
    position: relative;

    .el-input {
      width: 100%;
    }

    .btn {
      width: 85px !important;
      height: 20px;
      line-height: 20px;
      border-radius: 20px;
      font-size: 12px;
      position: absolute;
      right: 5px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.attestation-form {
  .avatar-uploader {
    .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 108px;
    height: 108px;
    line-height: 108px;
    text-align: center;
  }

  .avatar {
    width: 108px;
    height: 108px;
    display: block;
  }
}

.btn {
  background: #1890FF;
  width: 80px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: #fff;
  border-radius: 5px;
  font-size: 14px;
  white-space: nowrap;
}

.btn-plain {
  border: 1px solid #D9D9D9;
  width: 80px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: #5A5A5A;
  border-radius: 5px;
  font-size: 14px;
  white-space: nowrap;
}

.btn:hover,
.btn-plain:hover {
  cursor: pointer;
}

.giveDialog {
  .dialog-modal {
    padding: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-shadow: 0 0 10px #ccc;
    font-size: 14px;
    line-height: 28px;
  }

  .el-dialog__header {
    text-align: center;
  }

  .el-dialog__body {
    padding: 0 20px 0 !important;
  }

  .el-dialog__footer {
    text-align: center;
  }
}
.tabs .el-tabs__header {
  margin: 0;
}
.file-upload{
  .el-form-item__content{
    display: flex;
    align-items: flex-end;
  }
  i:hover{
    cursor: pointer;
  }
}
.unbind-dialog{
  .el-dialog__title{
    font-size: 16px;
    color: #E6A23C;
  }
}
</style>
