/*
* @About gongw created by 2021/2/1
*/
import request from '@/lib/utils/request'

// 通过手机号查询面试者信息
export function getUserInfo (params) {
  return request({
    url: 'query',
    method: 'post',
    data: params
  })
}
//创建订单
export function pay (data) {
  return request({
    url: '/egs/pay/payTest',
    method: 'post',
    params: data
  })
}
/** 获取到webSocket的前缀 （ws://localhost） **/
export function getWebSocketPrefix () {
  // 获取网站域名 +  端口号
  let domain = document.location.protocol + '//' + document.location.host

  // 判断api_base_url 是否设置
  if (process.env.VUE_APP_API_BASE_URL && process.env.VUE_APP_API_BASE_URL !== '/') {
    domain = process.env.VUE_APP_API_BASE_URL
  }

  if (domain.startsWith('https:')) {
    return 'wss://' + domain.replace('https://', '')
  } else {
    return 'ws://' + domain.replace('http://', '')
  }
}
//查看首页资讯，通知----未登录
export function getInfo (data) {
  return request({
    url: '/buiness/egsinfo/listNews',
    method: 'post',
    data: data
  })
}
//查看首页资讯，通知----登录
export function getInfoByToken (data) {
  return request({
    url: '/buiness/egsinfo/listNewsToken',
    method: 'post',
    data: data
  })
}
//查看首页资讯，通知详情----未登录
export function getDetail (id,data) {
  return request({
    url: '/buiness/body/getBody/'+id,
    method: 'get',
    params: data
  })
}
//查看首页资讯，通知详情----登录
export function getDetailByToken (id,data) {
  return request({
    url: '/buiness/body/getBodyToken/'+id,
    method: 'get',
    params: data
  })
}
//查看更多通知----未登录
export function getNotice (data) {
  return request({
    url: '/buiness/egsinfo/listNoToken',
    method: 'get',
    params: data
  })
}
//查看更多通知----登录
export function getNoticeByToken (data) {
  return request({
    url: '/buiness/egsinfo/listToken',
    method: 'get',
    params: data
  })
}

//保函产品----未登录
export function getProduct (data) {
  return request({
    url: '/buiness/finorginfo/listNoToken',
    method: 'get',
    params: data
  })
}
//保函产品----登录
export function getProductByToken (data) {
  return request({
    url: '/buiness/finorginfo/listToken',
    method: 'get',
    params: data
  })
}
//数据汇总----未登录
export function getDataInfo (data) {
  return request({
    url: '/buiness/dataInfo/getMapNoToken',
    method: 'get',
    params: data
  })
}
//数据汇总----登录
export function getDataInfoByToken (data) {
  return request({
    url: '/buiness/dataInfo/getMap',
    method: 'get',
    params: data
  })
}
//帮助中心----未登录
export function getHelpInfo (data) {
  return request({
    url: '/buiness/egsinfo/listHelpNoToken',
    method: 'get',
    params: data
  })
}
//帮助中心----登录
export function getHelpInfoByToken (data) {
  return request({
    url: '/buiness/egsinfo/listHelpToken',
    method: 'get',
    params: data
  })
}
//消息中心----登录
export function getMsgInfo (data) {
  return request({
    url: '/system/message/list',
    method: 'get',
    params: data
  })
}
//真伪查询----未登录
export function getSearch (no,data) {
  return request({
    url: '/buiness/gat/ckGatTrueNoToken/'+no,
    method: 'get',
    params: data
  })
}
//真伪查询----登录
export function getSearchByToken (no,data) {
  return request({
    url: '/buiness/gat/ckGatTrue/'+no,
    method: 'get',
    params: data
  })
}